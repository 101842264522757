<template>

  <section id="ranking">

    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <h5 class="mb-0 color-gray">{{ title_component.subtitle }}</h5>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-lg-3 mb-3">
        <v-select 
          class="selvue-custom"
          :options="indicadores_list" 
          v-model="indicadores_selected"
          :clearable="false">
        </v-select>
      </div>
      <div class="col-12 col-lg-8 offset-lg-1 mb-3">
        <div class="row">
          <div class="col-12 col-lg-4 col-xl-6 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
                icon="filter" 
                class="color-secondary pe-1"
              />
              Filtrar
          </div>
          <div class="col-12 col-lg-8 col-xl-6">
            <div class="row" v-click-outside="handleCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormatymd(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
    
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormatymd(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div 
          v-for="(us, i) in ranking" :key="i"
          class="card card-shadow border-round-10 border-0 mb-3">
          <div class="card-body">
            <div class="d-flex align-items-lg-center">
              <div class="flex-shrink-0">
                <img class="img-obj img-obj-100 img-obj-circle" :src="user_default_img" v-if="!us.imagen_perfil"/>
                <img class="img-obj img-obj-100 img-obj-circle" :src="us.imagen_perfil" v-if="us.imagen_perfil"/>
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h6 class="font-title-bold color-secondary">{{ us.primer_nombre + " " + us.apellido_paterno }}</h6>
                    <p class="color-gray mb-0">{{ us.cargo.nombre_cargo == null || us.cargo.nombre_cargo == undefined ? 'Cargo del colaborador':us.cargo.nombre_cargo }}</p>
                    <font-awesome-icon icon="star" class="fs-5 color-yellow"/> Total: {{ us.total_reconocimientos }}
                  </div>
                  <div class="col-12 col-lg-6 mt-3 mt-lg-0 d-flex justify-content-end align-items-center">
                    <h3 class="mb-0">
                      <span 
                        v-for="(re, i) in valorFormat(us.reconocimientos)" :key="i"
                        class="badge border border-3 border-round-50" :style="`color: #${value_colors[re.nombre_valor]};`">
                          {{ re.nombre_valor }} <strong class="color-light">{{ re.total }}</strong>
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="show_spinner"/>

<!--     <div class="ranking">
      <div class="tabs">
        <SectionTabs :tabs="tab_component" />
      </div>
      <div class="ranking-top">
        <div class="ranking-top-select">
          <h3>{{ title_component.subtitle }}</h3>
          <div class="select-container" :class="open_indicadores_select_list && 'active'">
            <div class="select-btn" @click="openSelectIndicadores">
              <div class="item">
                {{ indicadores_selected }}
              </div>
              <div class="icon">
                <font-awesome-icon :icon="open_indicadores_select_list ? 'angle-up' : 'angle-down'">
                </font-awesome-icon>
              </div>
            </div>
            <div class="select-list" v-if="open_indicadores_select_list">
              <div v-for="(indi, k) in indicadores_list" :key="k" class="list-item" @click="selectIndicador(indi)">
                {{ indi.label }}
              </div>
            </div>
          </div>
        </div>
        <div class="ranking-top-filter">
          <div class="title">Filtrar por período:</div>
          <div class="calendar-container d-flex" v-click-outside="handleCalendar">
            <div class="calendar" :class="calendar_side" v-if="show_calendar">
              <v-calendar color="blue" :max-date="max_date" :min-date="min_date" :attributes="attributes"
                v-model="date_init" @dayclick="onDayClick" />
              <div class="border-arrow" :class="calendar_side">
                <div class="arrow"></div>
              </div>
            </div>
            <div class="start" @click="openCalendar('start-date')">
              <span>{{ init_date }}</span>
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
            <div class="end unactive" v-if="init_date == 'Inicio'">
              <span>{{ fecha_fin }}</span>
  
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
            <div class="end" v-if="init_date != 'Inicio'" @click="openCalendar('end-date')">
              <span>{{ fecha_fin }}</span>
              <div class="icon">
                <img :src="img_calendar" alt="" />
              </div>
            </div>
          </div>
          <div
            class="select-container"
            v-if="switch_selected !== 'year'"
            :class="open_select_list && 'active'"
          >
            <div class="select-btn" @click="openSelect('month')">
              <div class="item">
                {{ month_selected.nombre }}
              </div>
              <div class="icon">
                <font-awesome-icon
                  :icon="open_select_list ? 'angle-up' : 'angle-down'"
                >
                </font-awesome-icon>
              </div>
            </div>
            <div class="select-list" v-if="open_select_list == 'month'">
              <div
                v-for="(month, k) in month_list"
                :key="k"
                class="list-item"
                @click="selectMonth(month)"
              >
                {{ month.nombre }}
              </div>
            </div>
          </div>
          <div
            class="select-container year"
            :class="open_select_list == 'year' && 'active'"
          >
            <div class="select-btn" @click="openSelect('year')">
              <div class="item">
                {{ year_selected }}
              </div>
              <div class="icon">
                <font-awesome-icon
                  :icon="open_select_list == 'year' ? 'angle-up' : 'angle-down'"
                >
                </font-awesome-icon>
              </div>
            </div>
            <div class="select-list" v-if="open_select_list == 'year'">
              <div
                v-for="(year, k) in year_list"
                :key="k"
                class="list-item"
                @click="selectYear(year)"
              >
                {{ year }}
              </div>
            </div>
          </div>
          <div class="switch-container">
            <div
              class="left"
              :class="switch_selected == 'month' && 'active'"
              @click="switchItemSelect('month')"
            >
              Mensual
            </div>
            <div
              class="right"
              :class="switch_selected == 'year' && 'active'"
              @click="switchItemSelect('year')"
            >
              Anual
            </div>
          </div>
        </div>
      </div>

      <div class="ranking-container">
        <ul class="user-detail" v-if="user_selected !== null">
  
          <li class="user-detail-container" v-for="(us, i) in ranking" :key="i">
            <div class="user-detail-container-left">
              <div class="user-img">
                <img :src="user_default_img" v-if="!us.imagen_perfil" alt="" />
                <img :src="us.imagen_perfil" v-if="us.imagen_perfil" alt="" />
              </div>
              <div class="user-info">
                <div class="user-name">
                  {{ us.primer_nombre + " " + us.apellido_paterno }}
                </div>
                <div class="user-position">
                  {{ us.cargo.nombre_cargo == null || us.cargo.nombre_cargo == undefined ? 'Cargo':us.cargo.nombre_cargo }}
                </div>
  
                <div class="user-total">
                  <img :src="img"> Total: {{ us.total_reconocimientos }}
                </div>
              </div>
            </div>
            <div class="user-detail-container-right">
              <div class="total-recognitions">
                <div class="title">
                  <span class="icon">
                    <font-awesome-icon icon="star"></font-awesome-icon>
                  </span>
                  Total Reconocimientos:
                </div>
                <div class="num">
                  {{us.total_reconocimientos}}
                </div>
              </div>
              <div class="recognitions-details">
  
                <div class="detail" v-for="(re, i) in valorFormat(us.reconocimientos)" :key="i">
                  <div class="title" :style="`color: #${value_colors[re.nombre_valor]};`">
                    {{ re.nombre_valor }}:
                  </div>
                  <div class="num">
                    {{ re.total }}
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
  
      <Spinner v-if="show_spinner" />
    </div> -->
  </section>

</template>

<script>
import SectionTabs from "../Section/SectionTabs.vue";
import gql from "graphql-tag";
import { GET_VALORES } from "@/apollo/queries";
import Spinner from "../Spinner.vue";

const GET_RC_MONTH = gql`
  query masReconocidosMonthRc(
    $id_empresa: String!
    $fecha_inicio: String!
    $fecha_final: String! 
  ) {
    totalReconocidosYearMonthReconocimientos(
      id_empresa: $id_empresa
        fecha_inicio: $fecha_inicio
        fecha_final: $fecha_final
    ) {
      id_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      rut_usuario
      dv_usuario
      imagen_perfil
      genero
      total_reconocimientos
      cargo {
        id_cargo
        nombre_cargo
      }
      sucursal {
        id_sucursal
        nombre_sucursal
      }
      gerencia {
        id_gerencia
        nombre_gerencia
        bussiness_unit
        unit_number
      }

      reconocimientos {
        id_reconocimiento
        url_imagen
        comentario_reconocimiento
        fecha_creacion
        visto
        nombre_grupo
        valor_comportamiento {
          valor {
            id_valor
            nombre_valor
            color
            id_estado_fk
          }
        }
      }
      reconocimientos_enviados{
        id_reconocimiento
        url_imagen
        comentario_reconocimiento
        fecha_creacion
        visto
        nombre_grupo
        valor_comportamiento {
          valor {
            id_valor
            nombre_valor
            color
            id_estado_fk
          }
        }
      }
      reconocimientos_valor {
        id_valor
        nombre_valor
        cantidad
      }
      
    }
  }
`;

export default {
  components: { SectionTabs,Spinner },
  data() {
    return {
      show_spinner: false,
      tab_component: [
        {
          name: "Dashboard",
          route: "recognize-Dashboard",
        },
        {
          name: "Indicadores",
          route: "recognize-ranking",
        },
      ],
      indicadores_list: [
        { label: "Año", route: "recognize-year" },
        { label: "Gerencia", route: "recognize-management" },
        { label: "Planta", route: "recognize-age" },
        { label: "Top 20", route: "recognize-ranking" },
        { label: "Consultas por colaborador", route: "recognize-contributor" },
        { label: "Detalles de reconocimiento", route: "recognize-reports" },
      ],
      indicadores_selected: "Top 20",
      open_indicadores_select_list: false,

      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      title_component: {
        title: "Reconoce",
        subtitle: "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
      },
      open_select_list: false,
      year_list: ["2022", "2023", "2024","2025", "2026"],
      year_selected: "2022",
      month_list: [
        { nombre: "Enero", id: "1" },
        { nombre: "Febrero", id: "2" },
        { nombre: "Marzo", id: "3" },
        { nombre: "Abril", id: "4" },
        { nombre: "Mayo", id: "5" },
        { nombre: "Junio", id: "6" },
        { nombre: "Julio", id: "7" },
        { nombre: "Agosto", id: "8" },
        { nombre: "Septiembre", id: "9" },
        { nombre: "Octubre", id: "10" },
        { nombre: "Noviembre", id: "11" },
        { nombre: "Diciembre", id: "12" },
      ],
      month_selected: { nombre: "Enero", id: "1" },
      switch_selected: "year",
      user_selected: "sdkjh",
      user_default_img: require("@/assets/img/user_default_img.svg"),
      colors:['#357BF3','#23BE61','#F26F28','#F6C700'],
      img: require("@/assets/img/reconoze/ball-star.svg"),
      ranking: [],
      user_id: this.$ls.get("user").id_usuario,
      valores:[],
      value_colors:[],

      show_calendar: false,
      calendar_side: "",
      init_date: "Inicio",
      end_date: "Término",
      max_date: "",
      min_date: "",
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
    };
  },
  mounted() {
    this.end_date       = this.dateNewFormat(new Date(),1);
    this.init_date    = this.dateNewFormat(this.addDays(new Date(), -30),1) 
    this.getTopYear();
    this.getValores();
  },
  watch: {
  init_date(){
    this.getTopYear();
  },
  end_date(){
    this.getTopYear();
  },
  indicadores_selected(value) {
    this.$router.push({ name: value.route });
  }
},
computed: {
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
  methods: {
    getTopYear() {
      this.ranking=[];
      
      this.show_spinner= true;
      this.$apollo
          .query({
            query: GET_RC_MONTH,
            variables: {
              id_empresa: this.id_empresa,
              fecha_inicio: this.init_date,
              fecha_final: this.end_date,
            },
          })
          .then((res) => {
            var resp = res.data.totalReconocidosYearMonthReconocimientos;
            console.log('RESPUESTA WS',resp);
            this.ranking = resp;
            this.show_spinner= false;
          })
          .catch((err) => {
            console.log("err", err);
            this.show_spinner= false;
          });
      

    },
/* var index = this.comtent_objets.findIndex(
  (ob) => ob.title == this.edit_selected_texto.title
); */

    getValores(){
      this.value_colors={};
      this.$apollo
          .query({
            query: GET_VALORES,
            variables: {
              id_empresa: this.id_empresa,
            },
          })
          .then((res) => {
            var resp = res.data.valoresReconocimientos;
            this.valores = resp;
            
            resp.forEach(el => { 
            this.value_colors[el.nombre_valor] = el.color});
          })
          .catch((err) => {
            console.log("err", err);
          });
    },
    openSelect(item) {
      if (item == this.open_select_list) {
        this.open_select_list = "";
      } else {
        this.open_select_list = item;
      }
    },
    selectYear(year) {
      this.year_selected = year;
      this.open_select_list = "";
      setTimeout(() => {
        this.open_select_list = "";
      }, 400);
      this.getTopYear();
    },
    selectMonth(month) {
      this.month_selected = month;
      this.open_select_list = "";
      setTimeout(() => {
        this.open_select_list = "";
      }, 400);
      this.getTopYear();
    },
    switchItemSelect(item) {
      this.switch_selected = item;
    },
    openSelectIndicadores() {
      this.open_indicadores_select_list = !this.open_indicadores_select_list;
    },
    selectIndicador(indi) {
      if (this.indicadores_selected != indi.name) {
        this.$router.push({ name: indi.route });
        this.indicadores_selected = indi.name;
      }
      this.open_indicadores_select_list = false;
    },
    valorFormat(element){
      let counter = []
      element.forEach(el => {
        let is_in = counter.find(ob => ob.nombre_valor ==  el.valor_comportamiento[0].valor.nombre_valor)
        //console.log(is_in)
        if (is_in != undefined) {
          let i = counter.findIndex(ob => ob.nombre_valor ==  el.valor_comportamiento[0].valor.nombre_valor)
          //console.log(counter)
          var new_total = counter[i].total + 1;
          counter.splice(i, 1, {nombre_valor:counter[i].nombre_valor,total:new_total} )

        }else{
          counter.push({nombre_valor:el.valor_comportamiento[0].valor.nombre_valor, total:1})

        }
      });
      return counter;
    },

    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        this.date_init =
          this.init_date != "Inicio" ? new Date(this.init_date) : "";
        this.max_date = this.end_date == "Término" ? "" : this.end_date;
        this.min_date = "";
      } else {
        var min = new Date(this.init_date);
        this.min_date = min.setDate(min.getDate() + 1);
        this.max_date = "";
        this.min_date = this.init_date == "Inicio" ? "" : this.init_date;
      }
    },
    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.init_date = day.id;
      } else {
        this.end_date = day.id;
      }
      if (this.init_date != "Inicio" || this.end_date != "Término") {
        this.getTopYear();
      }
      this.show_calendar = false;
    },
    handleCalendar() {
      // console.log("click outside calendar");
      this.show_calendar = false;
    },
    addDays(date, days) {
      var result = date;
      result.setDate(result.getDate() + days);
      return result;
    },
    dateNewFormat(date,type) {
      var this_day = date;
      var day = this_day.getDate();
      var month = this_day.getMonth();

      if(type == 1){
      return `${this_day.getFullYear()}-${
        month + 1 < 10 ? `0${month+ 1}` : month+ 1 
        }-${day + 1 < 10 ? `0${day+ 1}` : day+ 1}`;
      }
    },
  },
};
</script>
